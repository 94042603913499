class navigation {

	init() {
		this.hamburger();
		this.navigation();
	}

	/**
	 * Handles the main navigation
	 */
	hamburger() {
		$('.subnav__menu a').addEventListener('click', (e) => {
			e.preventDefault();

			$('body > header nav a.heading').forEach(el => {
				el.parentNode.classList.remove('inactive');
				el.parentNode.classList.remove('active');
			});

			const menu = $('body > header').classList;
			if (menu.contains('header--active')) {
				menu.remove('header--active');
				menu.remove('search--active');
				menu.remove('nav--active');
			} else {
				menu.add('header--active');
				menu.add('nav--active');
			}
		});

		$('.subnav__search a').addEventListener('click', (e) => {
			e.preventDefault();

			const menu = $('body > header').classList;
			menu.remove('nav--active');
			menu.add('header--active');
			menu.add('search--active');
		});

		$('.subnav__apply, .subnav__connect, .subnav__payment, .subnav__contact', true).forEach(el => {
			el.addEventListener('click', (e) => {
				const menu = $('body > header').classList;
				menu.remove('header--active');
				menu.remove('nav--active');
			});
		});

		if (window.innerWidth <= wcc.mobileBreakpoint) {
			$('body > header nav a.heading').forEach(el => {
				el.addEventListener('click', (e) => {
					if (el.parentNode.classList.contains('active')) {
						return true;
					}
					e.preventDefault();
					$('body > header nav a.heading').forEach(el => el.parentNode.classList.add('inactive'));
					el.parentNode.classList.remove('inactive');
					el.parentNode.classList.add('active');
					$('.nav__goback').classList.add('active');
				});
			});

			$('.nav__goback').addEventListener('click', e => {
				e.preventDefault();
				$('.nav__goback').classList.remove('active');
				$('body > header nav a.heading').forEach(el => {
					el.parentNode.classList.remove('inactive');
					el.parentNode.classList.remove('active');
				});
			});
		}
	}

	navigation() {
		$('body > header.container nav ul ul a').forEach(el => {
			el.addEventListener('click', (e) => {
				const menu = $('body > header').classList;
				menu.remove('header--active');
				menu.remove('search--active');
				menu.remove('nav--active');
			});
		});
	}

}

module.exports = new navigation;
