class accordion {
	init() {
		if(!$('.accordion')) return;

		$('.accordion article > h4 a').forEach(el => {
			el.addEventListener('click', e => {
				e.preventDefault();
				el.classList.toggle('active');
				el.parentNode.parentNode.querySelector('.content').classList.toggle('active');
			});
		});
	}
}

module.exports = new accordion;
