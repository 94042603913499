/**
 * Life at the College page scripts
 */
class college {
	init()
	{
		if (!$('#college .schools__school a.link')) {
			return;
		}
		this.deeplinks();
		this.clickThrough();
	}


	/**
	 * Deeplinking
	 */
	deeplinks()
	{
		const [, hash] = window.location.hash.split('#');
		const [id, ] = hash ? hash.split('-school') : [,];
		const el = $(`.schools__school.schools__school--${id} a.link`);

		if (el) {
			this.open(el);
		}
	}

	/**
	 * Opening banners on clickthrough
	 */
	clickThrough()
	{
		const self = this;
		$('#college .schools__school a.link').forEach(el => {
			el.addEventListener('click', e => {
				if (wcc.isMobile()) {
					e.preventDefault();
				}
				self.open(el);
			});
		});
	}


	open(el)
	{
		const root = $('#college #schools');
		const placeholder = $('#college #schools .schools__detail');
		const content = el.parentNode.querySelector('main');

		if (!wcc.isMobile()) {
			placeholder.innerHTML = content.outerHTML;
		}

		if (el.parentNode.classList.contains('active')) {
			// Closing
			placeholder.classList.remove('active');
			el.parentNode.classList.remove('active');
			root.classList.remove('active');
		} else {
			// Openning
			// 1. Close all first
			if (!wcc.isMobile()) {
				$('#college .schools__school').forEach(el => el.classList.remove('active'));
				$('#college .schools__school a.link').forEach(el => el.classList.remove('active'));
			}
			// 2. Open the desired tab
			placeholder.classList.add('active');
			el.parentNode.classList.toggle('active');

			if (!wcc.isMobile()) {
				root.classList.add('active');
			}
		}
	}
}

module.exports = new college;
