import scrollreveal from 'scrollreveal/dist/scrollreveal';

class reveal {
	init() {
		const sr = new scrollreveal();
		sr.reveal('img:not(.not-reveal)', { duration: 1000, scale: 1.1});
		sr.reveal('#college .college__row', { duration: 1000, scale: 1.1});
		sr.reveal('.schools__school', { duration: 1000, scale: 0.9});
	}
}

module.exports = new reveal;
