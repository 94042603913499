class datahref {
	init() {
		if (!$('[data-href]')) return;
		$('[data-href]', true).forEach(el => {
			el.addEventListener('click', e => {
				const href = el.getAttribute('data-href');
				if (href.indexOf('http') === 0) {
					window.location = href;
				} else {
					window.location.pathname = href;
				}
			});
		});
	}
}

module.exports = new datahref;
