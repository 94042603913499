class newsflash {
	init()
	{
		if (!$('#newsflash')) return;

		$('#newsflash a.close').addEventListener('click', e => {
			e.preventDefault();
			_(this);
			const [url, id] = e.target.href.split('#');
			document.cookie = `wcc_newsflash=${id}`;
			$('#newsflash').classList.remove('active');
		});
	}
}

module.exports = new newsflash;
