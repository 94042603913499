require('waypoints/lib/noframework.waypoints');

class waypoints {

	constructor() {}

	init() {
		if (!$('[data-waypoint]')) return;

		this.waypoints = $('[data-waypoint]');
		this.waypointLines = '';
		if (this.waypoints.length == undefined) {
			this.waypoints = [this.waypoints];
		}

		this.createNavigation();
		this.fixWaypoints();
		this.waypointsClicks();
	}

	/**
	 * Creates the waypoints fixed subnavigation
	 */
	createNavigation() {
		this.waypoints.forEach(el => {
			const index = [].indexOf.call($('[data-waypoint]'), el);
			const id = el.previousElementSibling ? el.previousElementSibling.getAttribute('id') : false;
			const title = el.getAttribute('data-waypoint-title');
			const category = el.getAttribute('data-category');
			const action = el.getAttribute('data-action');
			const label = el.getAttribute('data-label');

			if (!id) return;

			this.waypointLines += `<li><a data-scroll data-category="${category}" data-action="${action}" data-label="${label}" href="#${id}" class="${this.waypointLines == '' ? 'active' : ''}">${title}</a></li>`;
			new Waypoint({
				element: el,
				offset: '50%',
				handler: direction => {
					if (direction === 'down') {
						$('#waypoints a').forEach(el => el.classList.remove('active'));
						$(`#waypoints a[href='#${id}']`).classList.add('active');
					}
				}
			});
			new Waypoint({
				element: el,
				offset: 0,
				handler: direction => {
					if (direction === 'up') {
						$('#waypoints a').forEach(el => el.classList.remove('active'));
						$(`#waypoints a[href='#${id}']`).classList.add('active');
					}
				}
			});
			new Waypoint({
				element: el,
				offset: 'bottom-in-view',
				handler: direction => {
					if (direction === 'up') {
						$('#waypoints a').forEach(el => el.classList.remove('active'));
						$(`#waypoints a[href='#${id}']`).classList.add('active');
					}
				}
			});
		});
		$('#waypoints').innerHTML = `<nav><ul>${this.waypointLines}</ul><span class="cross"></span></nav>`;
	}

	waypointsClicks() {
		if (!$('#waypoints a')) return;

		$('#waypoints a').forEach(el => {
			el.addEventListener('click', e => {
				//e.preventDefault();
				//el.classList.add('active');
			});
		});
	}

	/**
	 * Set fixed position for waypoint navigation.
	 */
	fixWaypoints() {
		window.addEventListener('scroll', () => {
			const vh = Waypoint.viewportHeight() / 2;
			const top = $('[data-waypoint]')[0].getBoundingClientRect().top;
			if (top <= vh) {
				$('#waypoints').classList.add('fixed');
			} else {
				$('#waypoints').classList.remove('fixed');
			}
		});
	}
}

module.exports = new waypoints;
