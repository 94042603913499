class forms {
	init()
	{
		const self = this;

		if (!$('.siteform')) return;

		this.helpers();
		this.dropdowns();
		this.blur();
		this.otherFields();
		this.feedbackForm();
		this.netteForms();
		this.bookDates();
		this.conditionCheckbox();

		// If is disabled JS, do not hide inputs.
		if ($('.form__input--select.form__input--active'))
			$('.form__input--select.form__input--active').forEach(el => el.classList.remove('form__input--active'));

		$('.siteform').addEventListener('submit', e => {
			const form = e.target;

			if (!Nette.validateForm(form)) return;

			const data = new FormData(form);
			const headers = new Headers;
			headers.append('X-Requested-With', 'XMLHttpRequest');

			form.querySelector('[type=submit]').classList.add('sending');
			form.querySelector('[type=submit]').disabled = true;

			if (form.classList.contains('ajax')) {
				e.preventDefault();
				fetch(form.action, {method: form.method, headers: headers, body: data})
				.then(res => {
					if (!res.ok) {
 						throw Error(res.statusText);
					} else {
						return res.json();
					}
				})
				.then(data => {
					if (data.status == 'ok') {
						form.classList.add('success');
					} else {
						data.errors.forEach(error => {
							throw Error('Form contains errors.');
						});
					}
				})
				.catch(error => {
					form.querySelector('[type=submit]').classList.remove('sending');
					form.querySelector('[type=submit]').disabled = false;
					form.querySelector('.form-error').innerHTML = 'Sorry, we could not save your request. Try again or contact us via email.';
				});
			}
		});
	}


	/**
	 * Toggles dropdowns
	 */
	dropdowns()
	{
		let dropdowns = $('.form__input--select, .form__input--group');
		if (!dropdowns) return;

		dropdowns = dropdowns.length === undefined ? [dropdowns] : dropdowns;
		dropdowns.forEach(el => {
			el.querySelector('.form__label').addEventListener('click', e => {
				el.classList.toggle('form__input--active');
			});
		});
	}


	/**
	 * Functionality for others fields
	 */
	otherFields()
	{
		if (!$('.form__radio .input__others')) return;

		$('.form__radio .input__others').forEach(el => {
			el.addEventListener('keyup', e => {
				this.helpers.findParent(el, 'form__radio').querySelector('input[type=radio]').value = el.value;
			});
		})
	}


	/**
	 * If input is not filled, remove active class.
	 */
	blur()
	{
		if (!$('.siteform input[type=text], .siteform input[type=email], .siteform input[type=tel]')) return;

		let elements = $('.siteform input[type=text], .siteform input[type=email], .siteform input[type=tel]');
		if (elements.length === undefined) {
			elements = [elements];
		}

		elements.forEach(el => {
			el.addEventListener('blur', e => {
				if (el.value != '') {
					el.classList.add('active');
				} else {
					el.classList.remove('active');
				}
			});
		});
	}


	/**
	 * Special functions for feedback form.
	 */
	feedbackForm()
	{
		if (!$('.form__feedback')) return;
		$('.form__row--feedback label.form__radio').forEach(el => {
			el.addEventListener('click', e => {
				$('.form__feedback').classList.add('active');
			});
		});
	}


	netteForms()
	{
		const self = this;
		Nette.showFormErrors = function(form, errors) {
			let messages = [],
				focusElem;

			$('.form__input', true).forEach(el => {
				el.classList.remove('error');
			});

			for (var i = 0; i < errors.length; i++) {
				var elem = errors[i].element, message = errors[i].message;

				const parent = self.helpers.findParent(elem, 'form__input');
				parent.classList.add('error');

				if (!focusElem && elem.focus) {
					focusElem = elem;
					focusElem.focus();
				}
			}
		};
	}


	/**
	 * Dynamic switcher for Request a tour date form
	 */
	bookDates()
	{
		if (!$('#booktour__switcher')) return;

		$('#booktour__dates label', true).forEach(el => {
			el.classList.add('hidden');
		});

		$('#booktour__switcher label', true).forEach(el => {
			el.addEventListener('click', e => {
				const id = el.getAttribute('data-date-toggle');
				$(`#booktour__dates label`, true).forEach(date => {
					date.classList.add('hidden');
					date.querySelector('input').checked = false;
				});
				$(`#booktour__dates label.${id}`, true).forEach(date => {
					date.classList.remove('hidden');
				});
			});
		});
	}

	/**
	 * If ticket, show fields
	 */
	conditionCheckbox()
	{
		if (!$('.form__checkbox--conditional')) return;
		$('.form__checkbox--conditional', true).forEach(el => {
			el.addEventListener('click', e => {
				const id = el.getAttribute('for');
				$(`.form__row[data-conditional="${id}"]`, true).forEach(el => {
					$(`#${id}`).checked ? el.classList.remove('hidden') : el.classList.add('hidden');
				});
			});
		});
	}

	/**
	 * Set of helpres
	 */
	helpers()
	{
		this.helpers = {
			/**
			 * jQuery's closest()
			 */
			findParent: function(parent, selector) {
				while (!parent.classList.contains(selector)) {
					parent = parent.parentNode;
				}
				return parent;
			}
		};
	}

}

module.exports = new forms;
