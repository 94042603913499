/**
 * Carousel javascripts
 */
class carousel {

	init() {
		const self = this;
		// Public
		this.settings = {} || this.settings;
		this.settings.loopDelay = 7; // In seconds

		// Private
		this.translateY = 0;
		this.divider = $('.carousel__slide') ? $('.carousel__slide').length : 0;
		this.interval = null;

		document.addEventListener('DOMContentLoaded', this.carousel());
		window.onresize = function() {
			self.carousel(true);
		};
	}

	carousel(resize = false) {
		if (!$('.carousel__slide')) return;

		// Fill slides
		$('#carousel .carousel__slide', true).forEach((el, i) => {
			const index = i + 1
			const image = el.getAttribute('data-slide');
			let imageMobile = el.getAttribute('data-slide-mobile-small');

			if (window.devicePixelRatio >= 1.5) {
				imageMobile =  el.getAttribute('data-slide-mobile');
			}


			if (window.innerWidth > wcc.mobileBreakpoint) {
				el.style.backgroundImage = `url(${image})`;
			} else {
				el.style.backgroundImage = `url(${imageMobile})`;
			}

			const before = $(`#carousel .carousel__heading--heading${index}`).getAttribute('data-slide-before');
			const after = $(`#carousel .carousel__heading--heading${index}`).getAttribute('data-slide-after');
			try {
				document.styleSheets[0].addRule(`#carousel .carousel__heading--heading${index}::before`, `background-image: url(${before})`);
			} catch (e) {
				document.styleSheets[0].insertRule(`#carousel .carousel__heading--heading${index}::before { background-image: url(${before}) }`, 1);
			}
			try {
				document.styleSheets[0].addRule(`#carousel .carousel__heading--heading${index}::after`, `background-image: url(${after})`);
			} catch (e) {
				document.styleSheets[0].insertRule(`#carousel .carousel__heading--heading${index}::after { background-image: url(${after}) }`, 1);
			}
		});

		// Select corect slide
		if ($('#carousel .carousel__counter a'))
			$('#carousel .carousel__counter a', true).forEach(el => {
				el.addEventListener('click', (e) => {
					e.preventDefault();
					this.goTo(el.getAttribute('data-slide'));
				});
			});

		// Next slide
		if ($('#carousel nav a.next'))
			$('#carousel nav a.next').addEventListener('click', (e) => {
				e.preventDefault();
				this.goNext();
			});

		// Previous slide
		if ($('#carousel nav a.prev'))
			$('#carousel nav a.prev').addEventListener('click', (e) => {
				e.preventDefault();
				this.goPrev();
			});

		if (!resize) {
			this.loop();
		}
	}

	loop() {
		this.interval = setInterval(() => {
			this.goNext();
		}, this.settings.loopDelay * 1000);
	}

	goTo(id) {
		// Stop loop
		clearInterval(this.interval);

		// Slide
		const activeSlide = $('.carousel__slide.active');
		activeSlide.classList.remove('active');
		$(`.carousel__slide--slide${id}`).classList.add('active');

		// Heading
		const activeHeading = $('.carousel__heading.active');
		activeHeading.classList.remove('active');
		$(`.carousel__heading--heading${id}`).classList.add('active');

		//Counter
		$('#carousel .carousel__counter a.active').classList.remove('active');
		$(`#carousel .carousel__counter a[data-slide='${id}']`).classList.add('active');

		// Start loop
		this.loop();
	}

	goNext() {
		// Slide
		const activeId = $('.carousel__slide.active').getAttribute('data-slide-id');
		const nextActiveId = parseInt(activeId) + 1 > this.divider ? 1 : parseInt(activeId) + 1;
		this.goTo(nextActiveId);
	}

	goPrev() {
		// Slide
		const activeId = $('.carousel__slide.active').getAttribute('data-slide-id');
		const nextActiveId = parseInt(activeId) - 1 == 0 ? this.divider : parseInt(activeId) - 1;
		this.goTo(nextActiveId);
	}
}

module.exports = new carousel;
