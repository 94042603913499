window.wcc = window.wcc || {}
window.wcc.mobileBreakpoint = 768;
window.wcc.isMobile = () => window.innerWidth < wcc.mobileBreakpoint;

window.$ = (el, arr = false) => {
	const res = document.querySelectorAll(el);
	return res.length > 1 ? Array.prototype.slice.call(res) : (arr ? [res[0]] : res[0]);
}
window._ = (...msg) => console.log(...msg);

import smoothScroll from 'smooth-scroll/dist/js/smooth-scroll';
import carousel from './carousel.js';
import navigation from './navigation.js';
import college from './college.js';
import accordion from './accordion.js';
import datahref from './datahref.js';
import waypoints from './waypoints.js';
import forms from './forms.js';
import reveal from './reveal.js';
import newsflash from './newsflash.js';

{
	smoothScroll.init({
		speed: 1600,
		easing: 'easeOutQuart'
	});
	carousel.init();
	navigation.init();
	college.init();
	accordion.init();
	datahref.init();
	waypoints.init();
	reveal.init();
	forms.init();
	newsflash.init();
}
